.questionnaire {
    color: #122443;

    &__page {
        padding-top: 1.5rem;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;

        &-relative {
            position: relative;
        }

        &-title {
            padding: 0 50px;
            line-height: 150%;
        }

        &-subtitle {
            padding: 0 50px;
            line-height: 150%;
            opacity: 0.5;

            &.answers-amount {
                margin-top: 15px;
            }
        }
    }

    &__content {
        margin-top: 24px;
    }

    &__confirm-btn {
        margin-top: 40px;
        display: flex;
        justify-content: center;

        button {
            max-width: 300px;
        }
    }

    &__questionsNumber {
        margin-top: 5px;
        font-weight: 500;
        color: #122443;
        text-align: center;
        letter-spacing: 0.35px;
        opacity: 0.8;
    }

    &__step {
        position: relative;
        width: 76px;
        height: 32px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        font-size: 14px;
        color: #122443;
        font-weight: 500;
        line-height: 1.57;
        letter-spacing: 0.23px;

        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0 2px 10px -3px rgba(38, 51, 73, 0.1);

        p:last-child {
            z-index: 1;
            color: #fff;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: -2px;
            width: 44px;
            height: 32px;
            transform: skewX(-10deg);
            background-image: linear-gradient(to left, #00c6ff, #0072ff);
        }
    }

    &__title {
        margin: 1.5rem 0;
        color: #122443;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: 0.5px;
    }

    &__input,
    &__btn {
        width: 100%;
        padding: 12px 0 10px;
        font-family: inherit;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: 0.3px;
        background-image: linear-gradient(to left, rgba(18, 36, 67, 0.1), rgba(18, 36, 67, 0.1));
        background-repeat: no-repeat;
        background-position: left bottom;
        background-size: 100% 1px;
        text-align: left;
        color: #122443;
        resize: none;

        &::placeholder {
            font-weight: 400;
            font-style: italic;
        }

        &-answer {
            padding: 12px 10px 10px;
            transition: background-color 0.3s;

            @media (hover: hover) {
                &:hover {
                    background-color: rgba(47, 141, 255, 0.5);
                }
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;

                @media (hover: hover) {
                    &:hover {
                        background-color: transparent;
                    }
                }
            }

            &.selected {
                opacity: 1;
                background-color: rgba(47, 141, 255, 0.3);

                @media (hover: hover) {
                    &:hover {
                        background-color: rgba(47, 141, 255, 0.5);
                    }
                }
            }
        }
    }

    &__input::placeholder {
        color: rgba(18, 36, 67, 0.4);
    }

    &__form {
        flex-grow: 1;
        margin-top: 24px;

        &-item {
            margin-bottom: 20px;

            p {
                text-transform: uppercase;
                color: #122443;
                font-size: 10px;
                font-weight: 500;
                line-height: 1.5;
                letter-spacing: 1px;
                text-align: left;
            }

            &-btn {
                display: flex;
                justify-content: space-between;
                align-items: center;
                column-gap: 15px;
                background-image: linear-gradient(to left, rgba(18, 36, 67, 0.1), rgba(18, 36, 67, 0.1));
                background-repeat: no-repeat;
                background-position: left bottom;
                background-size: 100% 1px;
                padding: 12px 0 10px;

                button {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    color: rgba(18, 36, 67, 0.4);

                    &.chosen {
                        color: #122443;
                    }

                    &:not(:last-child) {
                        padding: 0;
                        background: none;
                    }
                }
            }

            span {
                display: inline-block;
                margin-top: 4px;
                font-size: 14px;
                color: #cf0000;
            }

            &-radio {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 10px;

                & > label {
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    cursor: pointer;

                    &.attempt {
                        color: #cf0000;
                    }
                }
            }

            & .select {
                width: 100%;
                height: 1px;
                background-image: linear-gradient(to left, rgba(18, 36, 67, 0.1), rgba(18, 36, 67, 0.1));
            }

            &.error {
                & > input,
                & > textarea,
                & .select,
                & .radio {
                    background-image: linear-gradient(to left, rgba(255, 0, 0, 0.4), rgba(255, 0, 0, 0.4));
                }
            }
        }

        &-block-title {
            margin-bottom: 20px;
            font-family: inherit;
            font-weight: 500;
        }
    }

    &__city-find {
        padding: 0 60px;
        margin-bottom: 20px;

        input {
            width: 100%;
            padding: 10px;
            font-size: 16px;
            border-radius: 6px;
            color: #122443;
            background: rgba(18, 36, 67, 0.05);
            border: 1px solid rgba(18, 36, 67, 0.2);

            &::placeholder {
                color: #122443;
                font-style: italic;
                opacity: 0.45;
            }
        }
    }

    &__description {
        margin-top: 24px;

        ol {
            li:not(:first-child) {
                margin-top: 16px;
            }

            span {
                display: inline-block;
                margin-top: 6px;
                font-style: italic;

                b {
                    font-weight: 500;
                }
            }
        }
    }
}

.instruction-page {
    color: #122443;

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 20px;
        margin: 50px 0;

        &-title {
            margin-bottom: 10px;
            font-size: 14px;
            text-align: center;
        }

        &-item {
            padding: 0 15px;
            height: 43px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-flex {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__content {
        flex-grow: 1;

        p {
            line-height: 140%;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        a {
            color: #000;
            text-decoration: underline;
            transition: color 0.3s;

            @media (hover: hover) {
                &:hover {
                    color: #676767;
                }
            }
        }
    }

    &__not-accept {
        h3,
        h4,
        p {
            text-align: center;
        }

        h4 {
            font-size: 20px;
            font-weight: 600;
        }

        p {
            margin-top: 20px;
            font-size: 18px;
            font-weight: 500;
        }
    }
}
