.list,
.rassList {
    margin-left: 20px;
}

.rassTitle {
    font-weight: 600;
}

.rassList,
.rassNote {
    margin-top: 14px;
}

.rassList {
    li {
        margin-top: 5px;
    }
}

.attempt {
    color: #cf0000;
}

.rassNote {
    font-style: italic;
    opacity: 0.7;
}
