.root {
    width: 570px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid rgba(128, 128, 128, 0.4);
    border-radius: 12px;

    @media (max-width: 640px) {
        width: calc(100vw - 40px);
    }
}

.title {
    margin-bottom: 34px;
    padding-right: 40px;
    font-size: 22px;
    font-weight: 500;
    line-height: 130%;
}

.pointsTitle {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
}
