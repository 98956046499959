.wrapper {
    flex-grow: 1;
    margin-top: 24px;
}

.title {
    margin-bottom: 0;
    text-align: center;
}

.errorField {
    color: #c91100;
}

.grid {
    display: grid;
    gap: 10px;
}
