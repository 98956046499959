.wrapper {
    flex-grow: 1;
    margin-top: 16px;
}

.header {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    justify-content: space-between;
    padding: 0 10px;
}

.title {
    margin-bottom: 0;
    text-align: center;
}

.grid {
    display: grid;
    gap: 10px;
}

.item {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    gap: 10px;
    padding: 16px;
    color: rgba(18, 36, 67, 0.9);
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    border: 1px solid rgba(128, 128, 128, 0.4);
    cursor: pointer;
}

.itemDone {
    color: rgba(18, 36, 67, 0.65);
    border-color: rgba(0, 130, 9, 0.4);
    cursor: auto;
}

.itemBlocked {
    opacity: 0.5;
}

.itemButtons {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 28px;

    button {
        width: 32px;
        height: 28px;
        border: 1px solid rgba(18, 36, 67, 0.3);
        border-radius: 5px;
        background-color: #fff;

        @media (hover: hover) {
            transition: background-color 0.3s ease;

            &:hover {
                background-color: rgba(18, 36, 67, 0.1);
            }
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.success {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
        width: 300px;
        padding: 16px;
        color: #005000;
        font-weight: 500;
        background-color: #c2dbc2;
        border: 1px solid rgba(0, 80, 0, 0.4);
        text-align: center;
    }
}

.additionalTitle {
    margin: 20px 0;
    text-align: center;
}

.highlighted {
    border-color: #121212 !important;
}
