.title {
    margin-bottom: 0;
    text-align: center;
}

.filterBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button:not(:first-child) {
        margin-left: 6px;
    }
}

.list {
    margin-top: 12px;

    & > div:not(:first-child) {
        margin-top: 8px;
    }

    label {
        background-color: #fff;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }
}

.success {
    color: #00590a;
    border-color: rgba(0, 130, 9, 0.4) !important;

    label {
        background-color: rgba(146, 255, 22, 0.25) !important;
        cursor: not-allowed !important;
    }
}

.highlighted {
    border-color: #121212 !important;
}
