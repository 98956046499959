.listItem {
    display: block;
    padding: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #122443;
    border: 1px solid rgba(128, 128, 128, 0.4);

    &:not(:first-child) {
        margin-top: 10px;
    }
}

.itemHeader,
.itemContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.itemContent {
    margin-top: 8px;
    font-size: 14px;
    color: rgba(18, 36, 67, 0.4);
}

.itemStatus {
    font-size: 14px;
}

.inProgress {
    border-color: rgba(200, 118, 0, 0.5);

    span {
        color: rgb(200, 118, 0);
    }
}

.done {
    border-color: rgba(41, 129, 0, 0.5);

    span {
        color: rgb(41, 129, 0);
    }
}

.new {
    border-color: rgba(0, 99, 229, 0.5);

    span {
        color: #0063e5;
    }
}
