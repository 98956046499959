.wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    display: grid;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 20px;
    background: transparent;
    overflow-x: hidden;
    z-index: 100;
    transition: background-color 0.35s ease;

    &.active {
        background-color: rgba(0, 0, 0, 0.2);
    }
}

.inner {
    transform: scale(0);
    transition: transform 0.35s ease;
    z-index: 100;

    &.active {
        transform: scale(100%);
    }
}

.closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    cursor: pointer;
    z-index: 10;
}
