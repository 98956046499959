.addButton {
    display: flex;
    justify-content: center;

    button {
        width: 190px;
    }
}

.searchBlock {
    margin-top: 20px !important;
    background-color: #fff;
}

.examinationsBlock {
    margin-top: 30px;

    p {
        font-weight: 500;
        text-align: center;
    }
}
