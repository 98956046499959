.root {
    margin-top: 30px;
    padding: 0 10px;
    color: #122443;
}

.title {
    margin-bottom: 0;
    text-align: center;
}

.filterBtns {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button:not(:first-child) {
        margin-left: 6px;
    }
}

.list {
    margin-top: 12px;

    & > div:not(:first-child) {
        margin-top: 8px;
    }

    label {
        background-color: #fff;
        padding-top: 12px !important;
        padding-bottom: 12px !important;
    }
}

.highlighted {
    border-color: #121212 !important;
}
